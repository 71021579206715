<script setup lang="ts">
import type { PropType } from 'vue'
import type { LAvatarParserProp } from './types'
import { getInitials } from './utils'

const props = defineProps({
  username: {
    type: String,
    required: true,
  },
  initials: {
    type: String,
    default: null,
  },
  backgroundColor: {
    type: String,
    default: null,
  },
  color: {
    type: String,
    default: null,
  },
  customStyle: {
    type: Object,
    default: () => ({}),
  },
  inline: {
    type: Boolean,
    default: false,
  },
  size: {
    type: Number,
    default: 50,
  },
  src: {
    type: String,
    default: null,
  },
  rounded: {
    type: Boolean,
    default: true,
  },
  lighten: {
    type: Number,
    default: 80,
  },
  parser: {
    type: Function as PropType<LAvatarParserProp>,
    default: getInitials,
    validator: (parser: LAvatarParserProp) => typeof parser('John', getInitials) === 'string',
  },
})
const emit = defineEmits(['avatarInitials'])

// IMAGE
const isImage = computed(() => {
  return !imgError.value && Boolean(props.src)
})
const imgError = ref(false)
const onImgError = () => {
  imgError.value = true
}

// INITIAL
const userInitial = computed(() => {
  if (!isImage.value) {
    return props.initials || props.parser(props.username, getInitials)
  }
  return ''
})
onMounted(() => {
  if (!isImage.value) {
    emit('avatarInitials', props.username, userInitial.value)
  }
})
</script>

<template>
  <div
    class="vue-avatar--wrapper relative flex select-none items-center overflow-hidden rounded-full bg-purple-900 text-center"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
    }"
    aria-hidden="true"
  >
    <!-- this img is not displayed; it is used to detect failure-to-load of div background image -->
    <span class="w-full font-bold text-orange-500">{{ userInitial }}</span>
    <img
      v-if="isImage"
      class="absolute bottom-0 left-0 right-0 top-0"
      :src="src"
      referrerpolicy="no-referrer"
      @error="onImgError"
    />
  </div>
</template>
